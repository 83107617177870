<template>
  <b-form-datepicker
    class="date-picker"
    :class="{ 'date-picker-right-align': right }"
    v-bind="{
      value,
      min,
      max,
      locale,
      startWeekday: 1,
      dateDisabledFn,
      dateFormatOptions: format,
      placeholder: 'Выберите дату',
      valueAsDate: true,
      labelHelp: '',
      right,
      labeNoDateSelected,
    }"
    @input="(date) => {if (!useContext) onContext(date)}"
    @context="(context) => {if (useContext) onContext(context.selectedDate)}"
  >
  </b-form-datepicker>
</template>

<script>
export default {
  name: 'CommonDatePicker',
  props: {
    value: {
      type: [String, Date],
      default() {
        return ''
      },
    },
    placeholder: {
      type: [String],
      default() {
        return 'Выберите дату'
      },
    },
    format: {
      type: Object,
      default() {
        return {
          year: '2-digit',
          month: '2-digit',
          day: 'numeric',
          weekday: 'short',
        }
      },
    },
    locale: {
      type: [String, Array],
      default() {
        return 'ru'
      },
    },
    right: {
      type: Boolean,
      default: true,
    },
    min: {
      type: [String, Date],
      default() {
        return ''
      },
    },
    max: {
      type: [String, Date],
      default() {
        return ''
      },
    },
    labeNoDateSelected: {
      type: [String],
      default() {
        return 'Дата не выбрана'
      },
    },
    dateDisabledFn: {
      type: Function,
    },
    useContext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: this.value,
    }
  },
  methods: {
    onContext(date) {
      this.$emit('context', { selectedDate: date })
    },
  },
}
</script>

<style lang="scss">
.date-picker-right-align {
    position: relative;
    padding: 0 12px !important;
    > button {
        position: absolute;
        right: 0;
        display: block;
        height: 100% !important;
        background-color: inherit;
    }
    > label {
        overflow: hidden !important;
    }
}
</style>
