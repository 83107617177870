var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-datepicker',_vm._b({staticClass:"date-picker",class:{ 'date-picker-right-align': _vm.right },on:{"input":function (date) {if (!_vm.useContext) { _vm.onContext(date) }},"context":function (context) {if (_vm.useContext) { _vm.onContext(context.selectedDate) }}}},'b-form-datepicker',{
    value: _vm.value,
    min: _vm.min,
    max: _vm.max,
    locale: _vm.locale,
    startWeekday: 1,
    dateDisabledFn: _vm.dateDisabledFn,
    dateFormatOptions: _vm.format,
    placeholder: 'Выберите дату',
    valueAsDate: true,
    labelHelp: '',
    right: _vm.right,
    labeNoDateSelected: _vm.labeNoDateSelected,
  },false))}
var staticRenderFns = []

export { render, staticRenderFns }